import React, { useState } from "react";
import PropTypes from "prop-types";

export const EmployeeDataContext = React.createContext({});

function EmployeeDataProvider({ children }) {
  const [employees, setEmployees] = useState([
    {
      id: 100212,
      name: "Brian Bendtsen",
      status: null,
    },
    {
      id: 100213,
      name: "Lasse Pedersen",
      status: null,
    },
    {
      id: 100214,
      name: "Henrik Bo Nielsen",
      status: null,
    },
  ]);

  const updateEmployees = (eID, status) => {
    let updated = [...employees];
    const index = employees.map((e) => e.id).indexOf(parseInt(eID));
    updated[index].status = status;
    setEmployees(updated);
  };

  const updateFormStatus = (eID) => {
    let updated = [...employees];
    const index = employees.map((e) => e.id).indexOf(parseInt(eID));
    updated[index].formStatus = 1;
    setEmployees(updated);
  };

  return (
    <EmployeeDataContext.Provider
      value={{
        employees,
        updateEmployees,
        updateFormStatus,
      }}
    >
      {children}
    </EmployeeDataContext.Provider>
  );
}

EmployeeDataProvider.propTypes = {
  children: PropTypes.object,
};

export default EmployeeDataProvider;
