export default function numberOfFields(defaultFields, formData) {
  let fields = defaultFields;

  if (formData.q1.value === "q1_0") {
    fields += 1;
  }

  if (formData.q2.value === "q2_1") {
    fields += 1;
  }

  if (formData.q3.value === "q3_0") {
    fields += 1;
  }

  return fields;
}
