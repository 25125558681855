import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Header from "components/Header";
import styled from "styled-components";
import { FormDataContext } from "context/FormDataContext";
import { EmployeeDataContext } from "context/EmployeeDataContext";
import { useParams, useHistory } from "react-router-dom";
import RadioButtonsField from "components/form/questionnaire/RadioButtonsField";
import Question from "components/Question";
import handleFields from "utils/handleFields";
import handleValidatedFields from "utils/handleValidatedFields";

function Questionnaire() {
  const { formData } = useContext(FormDataContext);
  const { employees, updateFormStatus } = useContext(EmployeeDataContext);
  let { employeeId } = useParams();
  const index = employees.map((e) => e.id).indexOf(parseInt(employeeId));

  let history = useHistory();

  const handleFormSubmit = (eID) => {
    updateFormStatus(eID);
    history.push("/");
  };

  const labels = {
    q1: {
      label: `Er ${employees[index]?.name} (${employeeId}) pensioneret tjenestemand, eller får ${employees[index]?.name} (${employeeId}) udbetalt pension fra en pensionsordning, hvortil det offentlige har ydet bidrag fx en arbejdsmarkedspension?`,
    },
    q2: {
      label: `Udfører ${employees[index]?.name} (${employeeId}) rengøringsopgaver uanset timetal?`,
    },
    q3: {
      label: `Har ${employees[index]?.name} (${employeeId}) kirketjeneste?`,
    },
    q4: {
      label: `Hvor meget arbejdstid bruger ${employees[index]?.name} (${employeeId}) på rengøring?`,
    },
    q5: {
      label: `Er ${employees[index]?.name} (${employeeId}) ansat som`,
    },
    q6: {
      label: `Har ${employees[index]?.name} (${employeeId}) mere end 8 timers arbejde om ugen i gennemsnit på en måned?`,
    },
  };

  const fields = handleFields(3, formData);
  const validatedFields = handleValidatedFields(formData);
  const isFormValid = fields === validatedFields ? true : false;

  return (
    <>
      <Header />
      <Main>
        <h2>
          Spørgeskema (<Link to={"/"}>Til forsiden</Link>)
        </h2>
        <div>
          {employees[index]?.name} ({employeeId})
        </div>
        <RadioButtonsField
          name="q1"
          label={labels.q1.label}
          options={[
            { label: "Ja", value: "q1_1" },
            { label: "Nej", value: "q1_0" },
          ]}
          value={formData.q1.value}
        />
        <Question
          name="q2"
          require_field="q1"
          require_value="q1_0"
          label={labels.q2.label}
        />
        <Question
          name="q3"
          require_field="q2"
          require_value="q2_1"
          label={labels.q3.label}
        />
        {formData.q3.value === "q3_0" && (
          <RadioButtonsField
            name="q4"
            label={labels.q4.label}
            options={[
              { label: "100% - 80%", value: "q6_1" },
              { label: "80 - 60%", value: "q6_2" },
              { label: "ca 50%", value: "q6_3" },
              { label: "40% - 20%", value: "q6_4" },
              { label: "20% - 0%", value: "q6_5" },
            ]}
            value={formData.q4.value}
            extendedRequired={{
              requiredField: "q3",
              requiredValue: "q3_0",
            }}
          />
        )}
        <RadioButtonsField
          name="q5"
          label={labels.q5.label}
          options={[
            { label: "Kordegn", value: "q5_1" },
            {
              label: "Sognemedhjælper/kirke- og kulturmedarbejder",
              value: "q5_2",
            },
            {
              label: "Kirkemusiker/-sanger",
              value: "q5_3",
            },
            {
              label: "Organist/korleder/klokkenist",
              value: "q5_4",
            },
            {
              label: "Graver",
              value: "q5_5",
            },
            {
              label: "Kirketjener",
              value: "q5_6",
            },
            {
              label:
                "Gravermedhjælper, gartner eller gartneriarbejder på en kirkegår?Sognemedhjælper/kirke- og kulturmedarbejder",
              value: "q5_7",
            },
            {
              label: "Rengøring",
              value: "q5_8",
            },
          ]}
          value={formData.q2.value}
        />
        <RadioButtonsField
          name="q6"
          label={labels.q6.label}
          options={[
            { label: "Ja", value: "q6_1" },
            { label: "Nej", value: "q6_0" },
          ]}
          value={formData.q6.value}
        />
        {isFormValid && (
          <Button onClick={() => handleFormSubmit(employeeId)}>Send</Button>
        )}
      </Main>
    </>
  );
}

export default Questionnaire;

const Main = styled.main`
  padding: 2em;
  max-width: 1180px;
  margin: 0 auto;
`;

const Button = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  color: #fff;
  padding: 8px 23px;
  border-radius: 8px;
  margin-top: 3em;
  display: inline-block;
  cursor: pointer;
  &:hover {
    background-color: black;
  }
`;
