import React, { useContext } from "react";
import { FormDataContext } from "context/FormDataContext";
import RadioButtonsField from "components/form/questionnaire/RadioButtonsField";
import PropTypes from "prop-types";

export default function Question({
  label,
  name,
  require_field,
  require_value,
}) {
  const { formData } = useContext(FormDataContext);
  if (formData[require_field].value === require_value) {
    return (
      <>
        <RadioButtonsField
          name={name}
          label={label}
          options={[
            { label: "Ja", value: `${name}_1` },
            { label: "Nej", value: `${name}_0` },
          ]}
          value={formData[name].value}
          extendedRequired={{
            requiredField: require_field,
            requiredValue: require_value,
          }}
        />
      </>
    );
  } else {
    return null;
  }
}

Question.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  require_field: PropTypes.string,
  require_value: PropTypes.string,
};
