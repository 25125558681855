import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import SelectField from "components/form/SelectField";
import { EmployeeDataContext } from "context/EmployeeDataContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const employeeStatusOptions = [
  {
    label: "Fratrådt eller fratræder snarest",
    value: 1,
  },
  {
    label: "Ansat som korsanger",
    value: 2,
  },
  {
    label: "Ingen af delene",
    value: 3,
  },
];

function ListOfEmployees({ header, subheader, employees }) {
  const { updateEmployees } = useContext(EmployeeDataContext);
  const list = employees.map((e, i) => {
    return (
      <Employee key={i}>
        <EmployeeID>{e.id}</EmployeeID>
        <EmployeeName>{e.name}</EmployeeName>
        <Status>
          {e.status === null && (
            <SelectField
              name={e.id}
              label=""
              options={employeeStatusOptions}
              value=""
            />
          )}
          {e.status === "3" && e.formStatus !== 1 && (
            <LinkToQuestionnaire to={`/questions/${e.id}`}>
              Udfyld spørgeskema
            </LinkToQuestionnaire>
          )}
          {e.status === "3" && e.formStatus === 1 && (
            <span>
              Afsluttet <FinishedIcon icon={faCircleCheck} />
            </span>
          )}
          {e.status === "1" && (
            <Actions>
              <label>Fratrådt eller fratræder snarest</label>
              <div>
                <RemoveBtn onClick={() => updateEmployees(e.id, null)}>
                  Fortryd
                </RemoveBtn>
              </div>
            </Actions>
          )}
          {e.status === "2" && (
            <Actions>
              <label>Ansat som korsanger</label>
              <div>
                <RemoveBtn onClick={() => updateEmployees(e.id, null)}>
                  Fortryd
                </RemoveBtn>
              </div>
            </Actions>
          )}
        </Status>
      </Employee>
    );
  });

  return (
    <List>
      <Heading2>{header}</Heading2>
      {subheader && <Heading3>{subheader}</Heading3>}
      <Line />
      {list}
    </List>
  );
}

export default ListOfEmployees;

ListOfEmployees.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  employees: PropTypes.array,
};

const FinishedIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.color.primary};
`;

const RemoveBtn = styled.a`
  cursor: pointer;
  font-size: 12px;
  text-decoration: underline;
`;

const Line = styled.hr`
  height: 1px;
  background-color: ${(props) => props.theme.color.primary};
  border: 0;
  width: 100%;
  margin: 0.5em 0 2.5em 0;
`;

const LinkToQuestionnaire = styled(Link)`
  background-color: ${(props) => props.theme.color.primary};
  font-size: 12px;
  text-decoration: none;
  border-radius: 8px;
  padding: 8px 23px;
  color: #fff;
`;

const List = styled.div`
  margin: 1em 0 3em 0;
`;

const Heading2 = styled.h2`
  font-size: 18px;
  font-weight: 400;
  margin: 0;
`;

const Heading3 = styled.h3`
  font-size: 15px;
  font-weight: 400;
  margin: 0;
`;

const EmployeeID = styled.div`
  flex: 0 0 100px;
`;

const EmployeeName = styled.div`
  flex: 0 0 200px;
`;

const Status = styled.div`
  flex: 1 0 200px;
`;

const Employee = styled.div`
  display: flex;
  margin: 1.5em 0;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1em;
  justify-content: space-between;
`;
