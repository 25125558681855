import styled from "styled-components";

function Header() {
  return (
    <HeaderWrapper>
      <HeaderContent>
        <Logo />
        <div>
          <Headline>Individuelt ansat i dit menighedsråd</Headline>
          <Description>
            Følgende spørgeskema har til hensigt at afdække hvilke
            ansættelsesforhold på individuelle vilkår i dit menighedsråd der er
            omfattet af deltidscirkulæret samt undersøge hvor mange
            rengøringsassistenter der er ansat i folkekirken
          </Description>
        </div>
      </HeaderContent>
    </HeaderWrapper>
  );
}

export default Header;

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.color.primary};
`;

const HeaderContent = styled.header`
  max-width: 1180px;
  margin: 0 auto;
  color: #ffffff;
  padding: 2em;
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  background-image: url("fit-logo.png");
  width: 90px;
  height: 90px;
  margin: 0 2em 0 0;
`;

const Headline = styled.h1`
  font-weight: 400;
  margin: 0;
`;

const Description = styled.p`
  font-weight: 400;
  margin: 0;
  padding: 0;
  width: 900px;
`;
