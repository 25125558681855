import React, { useContext } from "react";
import ListOfEmployees from "components/ListOfEmployees";
import { EmployeeDataContext } from "context/EmployeeDataContext";
import styled from "styled-components";
import Header from "components/Header";
import handleEmployees from "utils/handleEmployees";

function ManageEmployeeStatus() {
  const { employees } = useContext(EmployeeDataContext);

  const employeesNoStatus = employees.filter((e) => e.status === null);
  const employeesQualified = employees.filter((e) => e.status === "3");
  const employeesNotQualified = employees.filter(
    (e) => e.status === "1" || e.status === "2"
  );

  const questionnaireComplete = handleEmployees(employees);

  return (
    <>
      <Header />
      {questionnaireComplete && (
        <Message>
          Tak. Du har nu udfyldt spørgeskemaet for alle ansatte.
        </Message>
      )}
      <Main>
        <ListOfEmployees
          header="Ansatte i menighedsråd"
          subheader="Skemaet skal ikke udfyldes hvis den ansatte er fratrådt, fratræder
        snarest eller ansat som korsanger"
          employees={employeesNoStatus}
        />
        <ListOfEmployees
          header="Skemaet skal udfyldes for følgende ansatte"
          employees={employeesQualified}
        />
        <ListOfEmployees
          header="Skemaet skal IKKE udfyldes for følgende ansatte"
          employees={employeesNotQualified}
        />
      </Main>
    </>
  );
}

export default ManageEmployeeStatus;

const Main = styled.main`
  padding: 2em;
  max-width: 1180px;
  margin: 0 auto;
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
  margin: 0 auto;
  background-color: ${(props) => props.theme.color.success};
  color: #fff;
`;
