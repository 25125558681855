import ManageEmployeeStatus from "pages/ManageEmployeeStatus";
import Questionnaire from "pages/Questionnaire";
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import EmployeeDataProvider from "context/EmployeeDataContext";
import FormDataProvider from "context/FormDataContext";
import { ThemeProvider } from "styled-components";

function Router() {
  const theme = {
    color: {
      primary: "#03787C",
      secondary: "#B12929",
      success: "#8D943B",
    },
  };

  return (
    <EmployeeDataProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Switch>
            <Route path="/questions/:employeeId">
              <FormDataProvider>
                <Questionnaire />
              </FormDataProvider>
            </Route>
            <Route path="/">
              <ManageEmployeeStatus />
            </Route>
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    </EmployeeDataProvider>
  );
}

export default Router;
