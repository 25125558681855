export default function isFormComplete(employeeData) {
  const employees = Object.entries(employeeData)
    .map((e) => {
      if (e[1].status === "3" && e[1].formStatus === 1) {
        return null;
      }
      if (e[1].status === "1" || e[1].status === "2") return null;
      return true;
    })
    .filter((e) => e === true);
  if (employees.length === 0) return true;
}
