export default function validatedFields(formData) {
  const validated = Object.entries(formData)
    .map((e) => {
      if (!e[1].isValid) return null;

      if (e[1].extendedRequired) {
        const { requiredValue, requiredField } = e[1].extendedRequired;
        return requiredValue === formData[requiredField]?.value && e[1].isValid
          ? true
          : null;
      }
    })
    .filter((e) => e !== null);

  return validated.length;
}
