import React, { useState } from "react";
import PropTypes from "prop-types";

export const FormDataContext = React.createContext({});

function FormDataProvider({ children }) {
  const [employees] = useState([
    {
      id: 100212,
      name: "Brian Bendtsen",
      status: null,
    },
    {
      id: 100213,
      name: "Lasse Pedersen",
      status: null,
    },
    {
      id: 100214,
      name: "Henrik Bo Nielsen",
      status: null,
    },
  ]);

  const [formData, setFormData] = useState({
    q1: {
      value: "",
      isValid: false,
    },
    q2: {
      value: "",
      isValid: false,
    },
    q3: {
      value: "",
      isValid: false,
    },
    q4: {
      value: "",
      isValid: false,
    },
    q5: {
      value: "",
      isValid: false,
    },
    q6: {
      value: "",
      isValid: false,
    },
  });

  const updateFormData = (e, field, extendedRequired) => {
    const updated = {
      [field]: {
        value: e.target.value,
        isValid: true,
        extendedRequired,
      },
    };
    let updateForm = { ...formData, ...updated };
    setFormData(updateForm);
  };

  return (
    <FormDataContext.Provider
      value={{
        employees,
        formData,
        updateFormData,
      }}
    >
      {children}
    </FormDataContext.Provider>
  );
}

FormDataProvider.propTypes = {
  children: PropTypes.object,
};

export default FormDataProvider;
